import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import fileAddFill from '@iconify/icons-eva/file-add-fill';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
import cornerUpLeftFill from '@iconify/icons-eva/corner-up-left-fill';
import editFill from '@iconify/icons-eva/edit-fill';
import videoOffFill from '@iconify/icons-eva/video-off-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {
  Card,
  Stack,
  Typography,
  Button,
  Checkbox,
  Container,
  TableRow,
  TableBody,
  TableCell,
  CircularProgress,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';

// components
import Page from '../components/Page';
import InputTextField from '../components/InputTextField';
import { TableListToolbar, TableMoreMenu } from '../components/table';
import Label from '../components/Label';
import { Form } from '../components/useForm';
import { createAPIEndpoint, createUploadApiEndpoint, ENDPOINTS } from '../api';
import useTable from '../components/useTable';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  inputShort: {
    width: '80%'
  },
  inputLong: {
    margin: theme.spacing(1),
    width: '75%'
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

export default function TvEdit() {
  const { id } = useParams();
  const classes = useStyles();

  const [uploading, setUploading] = useState(false);

  const [resourceList, setResourceList] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [scaning, setScaning] = useState(false);
  const [scanButtonDisabled, setScanButtonDisabled] = useState(false);

  const [name, setName] = useState('');
  const [enName, setEnName] = useState('');
  const [alias, setAlias] = useState('');
  const [genre, setGenre] = useState('');
  const [country, setCountry] = useState('');
  const [lastUpdatedTime, setLastUpdatedTime] = useState('');
  const [imdb, setImdb] = useState('');
  const [douban, setDouban] = useState('');
  const [url, setUrl] = useState('');
  const [poster, setPoster] = useState('');
  const [description, setDescription] = useState('');
  const [enabled, setEnable] = useState(1);

  const codeDefine = JSON.parse(localStorage.getItem('resource_code_define'));

  function tableHead() {
    const head = [
      { id: 'id', label: 'ID', alignRight: false },
      { id: 'name', label: '资源名', alignRight: false },
      { id: 'season', label: '季', alignRight: false },
      { id: 'episode', label: '集', alignRight: false },
      { id: 'size', label: '大小', alignRight: false },
      { id: 'updatedTime', label: '采集时间', alignRight: false }
    ];
    Object.values(codeDefine).forEach((define) => {
      const h = { id: define.name, label: define.zhName, alignRight: false };
      head.push(h);
    });
    head.push({ id: 'action', label: '操作', alignRight: false });
    return head;
  }

  const {
    TblContainer,
    TblHead,
    TblPagination,
    setSelected,
    setTotalCount,
    selected,
    page,
    rowsPerPage,
    order,
    orderBy,
    totalCount
  } = useTable(resourceList, tableHead());

  const handleSubmit = (e) => {
    e.preventDefault();
    createAPIEndpoint(ENDPOINTS.UPDATE_TV_INFO)
      .post({
        id,
        name,
        enName,
        alias,
        genre,
        country,
        lastUpdatedTime,
        imdb,
        douban,
        url,
        poster,
        description
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '更新成功',
            type: 'success'
          });
        } else {
          setNotify({
            isOpen: true,
            message: '更新失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  };

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_TV_SERIES)
      .get({
        params: {
          tid: id
        }
      })
      .then((res) => {
        console.log(res);
        const info = res.data.data;
        setName(info.name === null ? '' : info.name);
        setEnName(info.enName === null ? '' : info.enName);
        setAlias(info.alias === null ? '' : info.alias);
        setCountry(info.country === null ? '' : info.country);
        setGenre(info.genre === null ? '' : info.genre);
        setLastUpdatedTime(info.lastUpdatedTime === null ? '' : info.lastUpdatedTime);
        setImdb(info.imdb === null ? '' : info.imdb);
        setDouban(info.douban === null ? '' : info.douban);
        setUrl(info.url === null ? '' : info.url);
        setPoster(info.poster === null ? '' : info.poster);
        setDescription(info.description === null ? '' : info.description);
        setEnable(info.enabled === null ? 1 : info.enabled);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, []);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_TV_RESOURCE_LIST)
      .get({
        params: {
          tid: id,
          page,
          pageSize: rowsPerPage,
          keyword: filterName,
          orderBy,
          order
        }
      })
      .then((res) => {
        console.log(res);
        const list = res.data.data.list.map((item) => ({
          id: item.id,
          tid: item.tid,
          name: item.name,
          season: item.season,
          episode: item.episode,
          size: item.size,
          updatedTime: item.updatedTime,
          rectime: item.rectime,
          resources: item.resources
        }));
        setTotalCount(res.data.data.count);
        setResourceList(list);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, [page, orderBy, order, rowsPerPage, filterName, totalCount]);

  const onTakeOff = (id) => {
    createAPIEndpoint(ENDPOINTS.TAKE_OFF_TV)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '下架成功',
            type: 'success'
          });
          setEnable(0);
        } else {
          setNotify({
            isOpen: true,
            message: '下架失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
  };

  const onPutOn = (id) => {
    createAPIEndpoint(ENDPOINTS.PUT_ON_TV)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '上架成功',
            type: 'success'
          });
          setEnable(1);
        } else {
          setNotify({
            isOpen: true,
            message: '上架失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
  };

  const constructTableCell = (resources) => {
    if (codeDefine == null) {
      return [];
    }
    const component = [];
    const cell1 = <TableCell align="left"> </TableCell>;
    const cell2 = (
      <TableCell align="left">
        <Icon icon={checkmarkFill} />
      </TableCell>
    );
    Object.values(codeDefine).forEach((define) => {
      let flg = false;
      Object.values(resources).forEach((res) => {
        if (res.name === define.name) {
          flg = true;
        }
      });
      component.push(flg ? cell2 : cell1);
    });
    return component;
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleGoBack = () => {
    window.history.back(-1);
  };

  const onDeleteResource = (id) => {
    createAPIEndpoint(ENDPOINTS.DELETE_TV_RESOURCE)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '删除成功',
            type: 'success'
          });
        } else {
          setNotify({
            isOpen: true,
            message: '删除失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    setTotalCount(totalCount - 1);
  };

  const onDelete = (id) => {
    createAPIEndpoint(ENDPOINTS.DELETE_TV_SERIES)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '删除成功',
            type: 'success'
          });
          handleGoBack();
        } else {
          setNotify({
            isOpen: true,
            message: '删除失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    setTotalCount(totalCount - 1);
  };

  const resetForm = () => {
    setName('');
    setEnName('');
    setAlias('');
    setGenre('');
    setCountry('');
    setLastUpdatedTime('');
    setImdb('');
    setDouban('');
    setUrl('');
    setPoster('');
    setDescription('');
  };

  const handleScan = () => {
    setScaning(true);
    setScanButtonDisabled(true);
    createAPIEndpoint(ENDPOINTS.SCAN_TEMP_TV)
      .post({
        id,
        name,
        enName
      })
      .then((res) => {
        const responseData = res.data.data;
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: responseData,
            type: 'success'
          });
          setTotalCount(totalCount + 1);
        } else {
          setNotify({
            isOpen: true,
            message: '执行失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      })
      .finally(() => {
        setScaning(false);
        setScanButtonDisabled(false);
      });
  };

  return (
    <Page title="美剧编辑 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            美剧编辑
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={cornerUpLeftFill} />}
            onClick={handleGoBack}
          >
            返回
          </Button>
        </Stack>
        <Card>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={3} className={classes.pageContent}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="h6" gutterBottom>
                  基本信息
                </Typography>
                <Label variant="ghost" color={(enabled === 0 && 'error') || 'success'}>
                  {enabled === 1 ? '正常' : '下架'}
                </Label>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="电影名"
                  name="name"
                  fullWidth
                  value={name}
                  setValue={setName}
                  required
                />
                <InputTextField
                  label="原名"
                  name="enName"
                  fullWidth
                  value={enName}
                  setValue={setEnName}
                  required
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="别名"
                  name="alias"
                  fullWidth
                  value={alias}
                  setValue={setAlias}
                />
                <InputTextField
                  label="类型"
                  name="genre"
                  fullWidth
                  value={genre}
                  setValue={setGenre}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="国家"
                  name="country"
                  fullWidth
                  value={country}
                  setValue={setCountry}
                />
                <InputTextField
                  label="IMDB地址（爬虫用）"
                  name="url"
                  fullWidth
                  value={url}
                  setValue={setUrl}
                  required
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="IMDB评分"
                  name="imdb"
                  fullWidth
                  value={imdb}
                  setValue={setImdb}
                />
                <InputTextField
                  label="豆瓣评分"
                  name="douban"
                  fullWidth
                  value={douban}
                  setValue={setDouban}
                />
              </Stack>
              <InputTextField
                label="海报"
                name="poster"
                fullWidth
                value={poster}
                setValue={setPoster}
                InputProps={{
                  endAdornment: (
                    <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        hidden
                        onChange={(e) => {
                          setUploading(true);
                          const { files } = e.target;
                          createUploadApiEndpoint(ENDPOINTS.UPLOAD_IMG)
                            .post({
                              files
                            })
                            .then((res) => {
                              setPoster(res.data.data || res.data.msg);
                            })
                            .catch((err) => {
                              console.log(err);
                            })
                            .finally(() => {
                              setUploading(false);
                            });
                        }}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        disabled={uploading}
                      >
                        {uploading ? (
                          <CircularProgress color="inherit" size={20} thickness={6} />
                        ) : (
                          <PhotoCamera />
                        )}
                      </IconButton>
                    </label>
                  )
                }}
                required
              />
              <InputTextField
                label="简介"
                name="description"
                fullWidth
                multiline
                rows={4}
                value={description}
                setValue={setDescription}
              />
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  startIcon={<Icon icon={checkmarkCircleFill} />}
                  type="submit"
                >
                  提交
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<Icon icon={refreshFill} />}
                  onClick={resetForm}
                >
                  重置
                </Button>
                {enabled === 1 && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Icon icon={videoOffFill} />}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: '确定要下架吗？',
                        subTitle: '下架之后只有管理员才能够查看',
                        onConfirm: () => {
                          onTakeOff(id);
                        }
                      });
                    }}
                  >
                    下架
                  </Button>
                )}
                {enabled === 0 && (
                  <Button
                    variant="contained"
                    color="error"
                    startIcon={<Icon icon={videoFill} />}
                    onClick={() => {
                      setConfirmDialog({
                        isOpen: true,
                        title: '确定要上架吗？',
                        subTitle: '上架之后可能会有版权问题',
                        onConfirm: () => {
                          onPutOn(id);
                        }
                      });
                    }}
                  >
                    上架
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<Icon icon={trash2Outline} />}
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: '确定要删除这条数据吗？',
                      subTitle: '删除后无法恢复',
                      onConfirm: () => {
                        onDelete(id);
                      }
                    });
                  }}
                >
                  删除
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Card>
        <Card className={classes.card}>
          <Stack spacing={3} className={classes.pageContent}>
            <Stack direction="column" spacing={2}>
              <Typography variant="h6" gutterBottom>
                下载资源
              </Typography>
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="flex-end">
              <Button
                variant="contained"
                startIcon={
                  scaning ? (
                    <CircularProgress color="inherit" size={20} thickness={6} />
                  ) : (
                    <Icon icon={fileAddFill} />
                  )
                }
                disabled={scanButtonDisabled}
                onClick={handleScan}
              >
                扫描
              </Button>
              <Button
                variant="contained"
                component={RouterLink}
                to="resource/add"
                startIcon={<Icon icon={plusFill} />}
              >
                新增
              </Button>
            </Stack>
            <Stack direction="column" spacing={2}>
              <div>
                <TableListToolbar
                  numSelected={selected.length}
                  filterName={filterName}
                  onFilterName={handleFilterByName}
                />
                <TblContainer>
                  <TblHead />
                  <TableBody>
                    {resourceList.map((row) => {
                      const { id, name, season, episode, size, updatedTime, resources } = row;
                      const isItemSelected = selected.indexOf(name) !== -1;

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, name)}
                            />
                          </TableCell>
                          <TableCell align="left">{id}</TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{season}</TableCell>
                          <TableCell align="left">{episode}</TableCell>
                          <TableCell align="left">{size}</TableCell>
                          <TableCell align="left">
                            {moment(updatedTime).format('YYYY-MM-DD HH:MM:SS')}
                          </TableCell>
                          {constructTableCell(resources)}

                          <TableCell align="right">
                            <TableMoreMenu>
                              <MenuItem
                                component={RouterLink}
                                to={`resource/${id}`}
                                sx={{ color: 'text.secondary' }}
                              >
                                <ListItemIcon>
                                  <Icon icon={editFill} width={24} height={24} />
                                </ListItemIcon>
                                <ListItemText
                                  primary="编辑"
                                  primaryTypographyProps={{ variant: 'body2' }}
                                />
                              </MenuItem>
                              <MenuItem
                                sx={{ color: 'text.secondary' }}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: '确定要删除这条数据吗？',
                                    subTitle: '删除后无法恢复',
                                    onConfirm: () => {
                                      onDeleteResource(id);
                                    }
                                  });
                                }}
                              >
                                <ListItemIcon>
                                  <Icon icon={trash2Outline} width={24} height={24} />
                                </ListItemIcon>
                                <ListItemText
                                  primary="删除"
                                  primaryTypographyProps={{ variant: 'body2' }}
                                />
                              </MenuItem>
                            </TableMoreMenu>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </TblContainer>
                <TblPagination />
              </div>
            </Stack>
          </Stack>
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
