// routes
import Router from './routes';
import AuthRouter from './authRoute';
// theme
import ThemeConfig from './theme';
// components
import ScrollToTop from './components/ScrollToTop';

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <AuthRouter />
      {/* <Router /> */}
    </ThemeConfig>
  );
}
