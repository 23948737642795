import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';

// ----------------------------------------------------------------------

export default function LoginRouter() {
  return useRoutes([
    {
      path: '/kanrisha',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: '/', element: <Navigate to="/kanrisha/login" /> }
      ]
    },

    { path: '/kanrisha/*', element: <Navigate to="/kanrisha/login" replace /> }
  ]);
}
