import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/samples/Products';
import Blog from './pages/samples/Blog';
import User from './pages/samples/User';
import MovieManage from './pages/MovieManage';
import TvManage from './pages/TvManage';
import MovieEdit from './pages/MovieEdit';
import MovieAdd from './pages/MovieAdd';
import TvEdit from './pages/TvEdit';
import TvAdd from './pages/TvAdd';
import NotFound from './pages/Page404';
import MovieResourceEdit from './pages/MovieResourceEdit';
import MovieResourceAdd from './pages/MovieResourceAdd';
import TvResourceEdit from './pages/TvResourceEdit';
import TvResourceAdd from './pages/TvResourceAdd';
import CommentManage from './pages/CommentManage';
import UntrackedMovieManage from './pages/UntrackedMovieManage';
import UntrackedTvManage from './pages/UntrackedTvManage';
import AccessLogManage from './pages/AccessLogManage';
import SearchLogManage from './pages/SearchLogManage';
import ResourceCodeEdit from './pages/ResourceCodeEdit';
import { Monitor } from './pages/Monitor';
import ResourceCodeAdd from './pages/ResourceCodeAdd';
import CodeDefineManage from './pages/CodeDefineManage';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/kanrisha/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '/', element: <Navigate to="/kanrisha/dashboard/app" replace /> },
        { path: 'app', element: <DashboardApp /> },
        { path: 'codeManage', element: <CodeDefineManage /> },
        { path: 'codeManage/edit/:id', element: <ResourceCodeEdit /> },
        { path: 'codeManage/add', element: <ResourceCodeAdd /> },
        { path: 'movieManage', element: <MovieManage /> },
        { path: 'movieManage/edit/:id', element: <MovieEdit /> },
        { path: 'movieManage/add', element: <MovieAdd /> },
        { path: 'movieManage/untracked/movie', element: <UntrackedMovieManage /> },
        { path: 'movieManage/edit/:id/resource/:id', element: <MovieResourceEdit /> },
        { path: 'movieManage/edit/:id/resource/add', element: <MovieResourceAdd /> },
        { path: 'tvManage', element: <TvManage /> },
        { path: 'tvManage/add', element: <TvAdd /> },
        { path: 'tvManage/untracked/tv', element: <UntrackedTvManage /> },
        { path: 'tvManage/edit/:id', element: <TvEdit /> },
        { path: 'tvManage/edit/:id/resource/:id', element: <TvResourceEdit /> },
        { path: 'tvManage/edit/:id/resource/add', element: <TvResourceAdd /> },
        { path: 'commentManage', element: <CommentManage /> },
        { path: 'log/accessLog', element: <AccessLogManage /> },
        { path: 'log/searchLog', element: <SearchLogManage /> },
        { path: 'log/monitor', element: <Monitor /> },
        { path: 'commentManage', element: <CommentManage /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> }
      ]
    },
    {
      path: '/kanrisha',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/kanrisha/dashboard" /> },
        { path: '*', element: <Navigate to="/kanrisha/404" /> }
      ]
    },

    { path: '/kanrisha/*', element: <Navigate to="/kanrisha/404" replace /> }
  ]);
}
