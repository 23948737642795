import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import messageCircleOutline from '@iconify/icons-eva/message-circle-outline';
import emailOutline from '@iconify/icons-eva/email-outline';
// material
import {
  Card,
  CardHeader,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
import Page from '../components/Page';
import { TableMoreMenu } from '../components/table';

import { createAPIEndpoint, ENDPOINTS } from '../api';
import Notification from '../components/Notification';
import Label from '../components/Label';
import ConfirmDialog from '../components/ConfirmDialog';
import Comment from '../components/_dashboard/comment/Comment';
import Feedback from '../components/_dashboard/comment/Feedback';
import useTable from '../components/useTable';
import * as timeUtils from '../utils/formatTime';

// ----------------------------------------------------------------------

export default function CommentManage() {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  return (
    <Page title="评论管理 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            评论管理
          </Typography>
        </Stack>
        <Comment />
        <Feedback />
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
