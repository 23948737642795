// material
import { Box, Grid, Container, Typography } from '@material-ui/core';
// components
import { useEffect } from 'react';
import Page from '../components/Page';
import {
  WebsiteNotify,
  SearchEngineStatus,
  TvCount,
  MovieCount,
  AccessQueue,
  StatisticsDistric,
  StatisticsOverview,
  Shortcut,
  CollectedMovieResource,
  CollectedTvResource,
  Cookies115
} from '../components/_dashboard/app';
import { createAPIEndpoint, ENDPOINTS } from '../api';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_ALL_RESOURCE_DEFINE_CODE)
      .get()
      .then((res) => {
        const record = res.data.data;
        localStorage.setItem('resource_code_define', JSON.stringify(record));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Page title="控制台 | BRILLIANT">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hi, Welcome back</Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AccessQueue />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <SearchEngineStatus />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MovieCount />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TvCount />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <StatisticsOverview />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <StatisticsDistric />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Shortcut />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <WebsiteNotify />
          </Grid>

          <Grid item xs={12}>
            <Cookies115 />
          </Grid>

          <Grid item xs={12}>
            <CollectedMovieResource />
          </Grid>

          <Grid item xs={12}>
            <CollectedTvResource />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
