import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import messageCircleOutline from '@iconify/icons-eva/message-circle-outline';
import emailOutline from '@iconify/icons-eva/email-outline';
// material
import {
  Card,
  CardHeader,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
import Page from '../../Page';
import { TableMoreMenu } from '../../table';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';
import Notification from '../../Notification';
import Label from '../../Label';
import ConfirmDialog from '../../ConfirmDialog';
import useTable from '../../useTable';
import * as timeUtils from '../../../utils/formatTime';

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

const TABLE_HEAD_CONTACT = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'subject', label: '主题', alignRight: false },
  { id: 'comment', label: '内容', alignRight: false },
  { id: 'nickname', label: '昵称', alignRight: false },
  { id: 'contact', label: '联系方式', alignRight: false },
  { id: 'createdTime', label: '时间', alignRight: false }
];

export default function Feedback() {
  const classes = useStyles();

  const [contactList, setContactList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const {
    TblContainer: TblContainerContact,
    TblHead: TblHeadContact,
    TblPagination: TblPaginationContact,
    setSelected: setSelectedContact,
    setTotalCount: setTotalCountContact,
    selected: selectedContact,
    page: pageContact,
    rowsPerPage: rowsPerPageContact,
    order: orderContact,
    orderBy: orderByContact,
    totalCount: totalCountContact
  } = useTable(contactList, TABLE_HEAD_CONTACT);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_CONTACT_LIST)
      .get({
        params: {
          page: pageContact,
          pageSize: rowsPerPageContact,
          orderBy: orderByContact,
          order: orderContact
        }
      })
      .then((res) => {
        console.log(res);
        const contactList = res.data.data.list.map((item) => ({
          id: item.id,
          comment: item.comment,
          nickname: item.nickname,
          contact: item.contact,
          subject: item.subject,
          createdTime: item.createdTime
        }));
        setTotalCountContact(res.data.data.count);
        setContactList(contactList);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, [pageContact, orderByContact, orderContact, rowsPerPageContact, totalCountContact]);

  const handleClickContact = (event, id) => {
    const selectedIndex = selectedContact.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedContact, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedContact.slice(1));
    } else if (selectedIndex === selectedContact.length - 1) {
      newSelected = newSelected.concat(selectedContact.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedContact.slice(0, selectedIndex),
        selectedContact.slice(selectedIndex + 1)
      );
    }
    setSelectedContact(newSelected);
  };

  return (
    <Card className={classes.card}>
      <CardHeader title="反馈" />
      <TblContainerContact>
        <TblHeadContact />
        <TableBody>
          {contactList.map((row) => {
            const { id, subject, comment, nickname, contact, createdTime } = row;
            const isItemSelected = selectedContact.indexOf(id) !== -1;

            return (
              <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => handleClickContact(event, id)}
                  />
                </TableCell>
                <TableCell align="left">{id}</TableCell>
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Icon icon={emailOutline} width={20} height={20} />
                    <Typography variant="subtitle2" noWrap>
                      {subject}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">{comment}</TableCell>
                <TableCell align="left">{nickname}</TableCell>
                <TableCell align="left">{contact}</TableCell>
                <TableCell align="left">{timeUtils.fDateTimeSuffix(createdTime)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TblContainerContact>
      <TblPaginationContact />
    </Card>
  );
}
