import { TextField, Container } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import Page from '../components/Page';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: 'rgb(215, 211, 182)'
  },
  resize: {
    fontSize: '0.75rem',
    color: 'rgb(53, 35, 33)',
    backgroundColor: 'rgb(215, 211, 182)'
  }
}));

export const Monitor = () => {
  const classes = useStyles();
  const [text, setText] = useState('');
  const [msg, setMsg] = useState('');
  const [error, setError] = useState(false);
  const manual = useRef(false);
  let client;

  const connect = () => {
    manual.current = false;
    client = new W3CWebSocket('wss://api.24pindao.com/ws/channel');
    // client = new W3CWebSocket('ws://localhost:8989/ws/channel');
    client.onopen = () => {
      setError(false);
      setMsg('');
      console.log('WebSocket Client Connected');
      client.send(
        JSON.stringify({
          type: 'user_enter',
          username: localStorage.getItem('username')
        })
      );
    };

    client.onmessage = (message) => {
      setError(false);
      setMsg('');
      const dataFromServer = JSON.parse(message.data);
      if (dataFromServer.type === 'content_change') {
        setText(`${dataFromServer.data.editorContent}\n`);
      }
    };

    client.onclose = (e) => {
      if (manual.current) {
        console.log('manual close, no action');
        return;
      }
      console.log('WebSocket Client Closed, Reconnecting.', e.reason);
      setMsg('连接已断开，正在重新连接');
      setError(true);
      let timeout = 1000;
      setTimeout(() => {
        connect();
      }, Math.min(10000, (timeout += timeout)));
    };

    client.onerror = (err) => {
      console.error('WebSocket Client Error, Close Socket.', err.message);
      setMsg('发生错误，请刷新页面');
      setError(true);
      client.close();
    };
  };

  useEffect(() => {
    connect();
    return () => {
      manual.current = true;
      client.close();
    };
  }, []);

  return (
    <Page title="日志监控 | BRILLIANT">
      <Container>
        <TextField
          className={classes.root}
          key="real-time-log"
          label="实时日志监控"
          variant="outlined"
          fullWidth
          multiline
          autoFocus
          minRows={25}
          maxRows={25}
          value={text}
          helperText={msg}
          error={error}
          InputProps={{
            classes: {
              input: classes.resize
            }
          }}
        />
      </Container>
    </Page>
  );
};
