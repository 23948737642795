import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import cookie from 'react-cookies';
import LoginRouter from './loginRoute';
// layouts
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
// router
import Router from './routes';
import { createAPIEndpoint, ENDPOINTS } from './api';

// ----------------------------------------------------------------------

export default function AuthRouter() {
  const roles = localStorage.getItem('roles');

  // 如果用户有权限，就渲染对应的路由
  if (roles && roles.includes('ADMIN')) {
    return <Router />;
  }

  return <LoginRouter />;
}
