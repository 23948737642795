import { useState } from 'react';
import { Table, TablePagination, TableContainer } from '@material-ui/core';
import Scrollbar from './Scrollbar';
import { TableListHead } from './table';

export default function useTable(records, headCells) {
  const pages = [10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(pages[page]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [selected, setSelected] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const TblContainer = (props) => (
    <Scrollbar>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>{props.children}</Table>
      </TableContainer>
    </Scrollbar>
  );

  const TblHead = (props) => {
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = records.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };

    return (
      <TableListHead
        order={order}
        orderBy={orderBy}
        headLabel={headCells}
        rowCount={records.length}
        numSelected={selected.length}
        onRequestSort={handleRequestSort}
        onSelectAllClick={handleSelectAllClick}
      />
    );
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TblPagination = () => (
    <TablePagination
      rowsPerPageOptions={pages}
      component="div"
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );

  return {
    TblContainer,
    TblHead,
    TblPagination,
    setSelected,
    setTotalCount,
    setPage,
    selected,
    page,
    rowsPerPage,
    order,
    orderBy,
    totalCount
  };
}
