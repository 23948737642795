import { Icon } from '@iconify/react';
import checkmarkCircle2Fill from '@iconify/icons-eva/checkmark-circle-2-fill';
import alertCircleFill from '@iconify/icons-eva/alert-circle-fill';
import closeCircleFill from '@iconify/icons-eva/close-circle-fill';
import slashFill from '@iconify/icons-eva/slash-fill';
import { useState, useEffect } from 'react';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { Card, Typography } from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  padding: theme.spacing(5, 0),
  color: theme.palette.info.darker,
  backgroundColor: theme.palette.info.lighter
}));

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
  color: theme.palette.info.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(theme.palette.info.dark, 0)} 0%, ${alpha(
    theme.palette.info.dark,
    0.24
  )} 100%)`
}));

// ----------------------------------------------------------------------

export default function AppNewUsers(props) {
  const [status, setStatus] = useState('');
  const [delay, setDelay] = useState(-1);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_SEARCH_ENGINE_STATUS)
      .get()
      .then((res) => {
        const record = res.data.data;
        setStatus(record.status);
        setDelay(record.delay);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  let icon;
  if (status === 'GREEN') {
    icon = <Icon icon={checkmarkCircle2Fill} width={24} height={24} />;
  } else if (status === 'YELLOW') {
    icon = <Icon icon={alertCircleFill} width={24} height={24} />;
  } else if (status === 'RED') {
    icon = <Icon icon={closeCircleFill} width={24} height={24} />;
  } else {
    icon = <Icon icon={slashFill} width={24} height={24} />;
  }

  return (
    <RootStyle>
      <IconWrapperStyle>{icon}</IconWrapperStyle>
      <Typography variant="h3">{`${delay}ms`}</Typography>
      <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
        搜索引擎状态
      </Typography>
    </RootStyle>
  );
}
