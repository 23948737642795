// material
import {
  Card,
  CardHeader,
  Table,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
import tvFill from '@iconify/icons-eva/tv-fill';
import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
// components
import Scrollbar from '../../Scrollbar';
import { TableListHead } from '../../table';
import * as timeUtils from '../../../utils/formatTime';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';

const MOVIE_TABLE_HEAD = [
  { id: 'name', label: '电影名', alignRight: false },
  { id: 'size', label: '大小', alignRight: false },
  { id: 'time', label: '采集时间', alignRight: false }
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function CollectedMovieResource() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [movieList, setMovieList] = useState([]);
  const [tvList, setTvList] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_TODAY_MOVIE)
      .get()
      .then((res) => {
        console.log(res);
        if (res.data.data != null) {
          const movieList = res.data.data.map((item) => ({
            id: item.id,
            name: item.name,
            size: item.size,
            time: item.rectime
          }));
          setMovieList(movieList);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleMovieRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleMovieSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = movieList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleMovieClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleMovieChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleMovieChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyMovieRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - movieList.length) : 0;

  const filteredMovies = applySortFilter(movieList, getComparator(order, orderBy), filterName);

  return (
    <Card>
      <CardHeader title="采集的电影资源" />
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={MOVIE_TABLE_HEAD}
              rowCount={movieList.length}
              numSelected={selected.length}
              onRequestSort={handleMovieRequestSort}
              onSelectAllClick={handleMovieSelectAllClick}
            />
            <TableBody>
              {filteredMovies
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const { id, name, size, time } = row;
                  const isItemSelected = selected.indexOf(name) !== -1;

                  return (
                    <TableRow
                      hover
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleMovieClick(event, name)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row" padding="none">
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Icon icon={tvFill} width={22} height={22} />
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="left">{size}</TableCell>
                      <TableCell align="left">{timeUtils.convertTimeFormat(time)}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyMovieRows > 0 && (
                <TableRow style={{ height: 53 * emptyMovieRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={movieList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleMovieChangePage}
        onRowsPerPageChange={handleMovieChangeRowsPerPage}
      />
    </Card>
  );
}
