import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
import { useState, useEffect } from 'react';
// material
import { Card, CardHeader, Box } from '@material-ui/core';
//
import { BaseOptionChart } from '../../charts';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';

// ----------------------------------------------------------------------

export default function StatisticsOverview() {
  const [dateArrayToday, setDateArrayToday] = useState([]);
  const [pvCountToday, setPvCountToday] = useState([]);
  const [visitorCountToday, setVisitorCountToday] = useState([]);
  const [ipCountToday, setIpCountToday] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_STATISTICS_OVERVIEW)
      .get()
      .then((res) => {
        const record = res.data.data;
        setDateArrayToday(record.dateArrayToday);
        setPvCountToday(record.pvCountToday);
        setVisitorCountToday(record.visitorCountToday);
        setIpCountToday(record.ipCountToday);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const chartData = [
    {
      name: '浏览量',
      type: 'column',
      data: pvCountToday
    },
    {
      name: '访客数',
      type: 'area',
      data: visitorCountToday
    },
    {
      name: 'IP 数',
      type: 'line',
      data: ipCountToday
    }
  ];

  const chartOptions = merge(BaseOptionChart(), {
    stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '11%', borderRadius: 4 } },
    fill: { type: ['solid', 'gradient', 'solid'] },
    labels: dateArrayToday,
    xaxis: { type: 'string' },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} 次`;
          }
          return y;
        }
      }
    }
  });

  return (
    <Card>
      <CardHeader title="Website Visits" subheader="(+43%) than last year" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={chartData} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
