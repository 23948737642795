import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import cubeFill from '@iconify/icons-eva/cube-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import atFill from '@iconify/icons-eva/at-fill';
import messageSquareFill from '@iconify/icons-eva/message-square-fill';
import tvFill from '@iconify/icons-eva/tv-fill';
import filmFill from '@iconify/icons-eva/film-fill';
import monitorOutline from '@iconify/icons-eva/monitor-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: '控制台',
    path: '/kanrisha/dashboard/app',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: '码值管理',
    path: '/kanrisha/dashboard/codeManage',
    icon: getIcon(cubeFill)
  },
  {
    title: '电影管理',
    path: '/kanrisha/dashboard/movieManage',
    icon: getIcon(filmFill)
  },
  {
    title: '美剧管理',
    path: '/kanrisha/dashboard/tvManage',
    icon: getIcon(tvFill)
  },
  {
    title: '评论管理',
    path: '/kanrisha/dashboard/commentManage',
    icon: getIcon(messageSquareFill)
  },
  {
    title: '访问记录',
    path: '/kanrisha/dashboard/log/accessLog',
    icon: getIcon(atFill)
  },
  {
    title: '搜索记录',
    path: '/kanrisha/dashboard/log/searchLog',
    icon: getIcon(searchFill)
  },
  {
    title: '日志监控',
    path: '/kanrisha/dashboard/log/monitor',
    icon: getIcon(monitorOutline)
  }
];

export default sidebarConfig;
