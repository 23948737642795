import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import messageCircleOutline from '@iconify/icons-eva/message-circle-outline';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  CardHeader,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
import { TableMoreMenu } from './table';

import { createAPIEndpoint, ENDPOINTS } from '../api';
import Label from './Label';
import ConfirmDialog from './ConfirmDialog';
import useTable from './useTable';
import * as timeUtils from '../utils/formatTime';

const TABLE_HEAD_CODE = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'code', label: '编码', alignRight: false },
  { id: 'name', label: '名称', alignRight: false },
  { id: 'zhName', label: '中文名', alignRight: false },
  { id: 'info', label: '额外信息', alignRight: false },
  { id: 'enable', label: '状态', alignRight: false },
  { id: '' }
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

export default function CodeDefine() {
  const classes = useStyles();

  const [codeList, setCodeList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const {
    TblContainer: TblContainerCode,
    TblHead: TblHeadCode,
    setSelected: setSelectedComment,
    selected: selectedComment
  } = useTable(codeList, TABLE_HEAD_CODE);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_ALL_RESOURCE_DEFINE_CODE)
      .get()
      .then((res) => {
        console.log(res);
        const codeList = res.data.data.map((item) => ({
          id: item.id,
          code: item.code,
          name: item.name,
          zhName: item.zhName,
          info: item.info,
          enable: item.enable
        }));
        setCodeList(codeList);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, []);

  // const handleClickComment = (event, id) => {
  //   const selectedIndex = selectedComment.indexOf(id);
  //   let newSelected = [];
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(selectedComment, id);
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(selectedComment.slice(1));
  //   } else if (selectedIndex === selectedComment.length - 1) {
  //     newSelected = newSelected.concat(selectedComment.slice(0, -1));
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(
  //       selectedComment.slice(0, selectedIndex),
  //       selectedComment.slice(selectedIndex + 1)
  //     );
  //   }
  //   setSelectedComment(newSelected);
  // };

  return (
    <Card>
      <CardHeader title="资源码" />
      <TblContainerCode>
        <TblHeadCode />
        <TableBody>
          {codeList.map((row) => {
            const { id, code, name, zhName, info, enable } = row;
            const isItemSelected = selectedComment.indexOf(id) !== -1;

            return (
              <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    // onChange={(event) => handleClickComment(event, id)}
                  />
                </TableCell>
                <TableCell align="left">{id}</TableCell>
                <TableCell align="left">{code}</TableCell>
                <TableCell align="left">{name}</TableCell>
                <TableCell align="left">{zhName}</TableCell>
                <TableCell align="left">{info}</TableCell>
                <TableCell align="left">
                  <Label variant="ghost" color={(enable === 1 && 'success') || 'error'}>
                    {enable === 1 ? '有效' : '无效'}
                  </Label>
                </TableCell>
                <TableCell align="right">
                  <TableMoreMenu>
                    <MenuItem
                      component={RouterLink}
                      to={`edit/${id}`}
                      sx={{ color: 'text.secondary' }}
                    >
                      <ListItemIcon>
                        <Icon icon={trash2Outline} width={24} height={24} />
                      </ListItemIcon>
                      <ListItemText primary="编辑" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                  </TableMoreMenu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TblContainerCode>
    </Card>
  );
}
