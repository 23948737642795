import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import inboxFill from '@iconify/icons-eva/inbox-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import videoOffFill from '@iconify/icons-eva/video-off-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import { TableListToolbar, TableMoreMenu } from '../components/table';

import { createAPIEndpoint, ENDPOINTS } from '../api';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';
import useTable from '../components/useTable';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'MID', alignRight: false },
  { id: 'name', label: '电影名', alignRight: false },
  { id: 'enName', label: '原名', alignRight: false },
  { id: 'release', label: '上映时间', alignRight: false },
  { id: 'enabled', label: '状态', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function MovieManage() {
  const [filterName, setFilterName] = useState('');
  const [movieList, setMovieList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    setSelected,
    setTotalCount,
    setPage,
    selected,
    page,
    rowsPerPage,
    order,
    orderBy,
    totalCount
  } = useTable(movieList, TABLE_HEAD);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_MOVIE_INFO_LIST)
      .get({
        params: {
          page,
          pageSize: rowsPerPage,
          keyword: filterName,
          orderBy,
          order
        }
      })
      .then((res) => {
        console.log(res);
        const movieList = res.data.data.list.map((item) => ({
          id: item.id,
          name: item.name,
          enName: item.enName,
          releaseDate: item.releaseDate,
          enabled: item.enabled
        }));
        setTotalCount(res.data.data.count);
        setMovieList(movieList);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, [page, orderBy, order, rowsPerPage, filterName, totalCount]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const onDelete = (id) => {
    createAPIEndpoint(ENDPOINTS.DELETE_MOVIE_INFO)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '删除成功',
            type: 'success'
          });
        } else {
          setNotify({
            isOpen: true,
            message: '删除失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    setTotalCount(totalCount - 1);
  };

  const onTakeOff = (id) => {
    createAPIEndpoint(ENDPOINTS.TAKE_OFF_MOVIE)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '下架成功',
            type: 'success'
          });
        } else {
          setNotify({
            isOpen: true,
            message: '下架失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    setTotalCount(totalCount - 1);
  };

  const onPutOn = (id) => {
    createAPIEndpoint(ENDPOINTS.PUT_ON_MOVIE)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '上架成功',
            type: 'success'
          });
          setTotalCount(totalCount - 1);
        } else {
          setNotify({
            isOpen: true,
            message: '上架失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  return (
    <Page title="电影管理 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            电影管理
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Button
              variant="contained"
              component={RouterLink}
              to="add"
              startIcon={<Icon icon={plusFill} />}
            >
              新增
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="untracked/movie"
              startIcon={<Icon icon={inboxFill} />}
            >
              查看未收录资源
            </Button>
          </Stack>
        </Stack>

        <Card>
          <TableListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TblContainer>
            <TblHead />
            <TableBody>
              {movieList.map((row) => {
                const { id, name, enName, releaseDate, enabled } = row;
                const isItemSelected = selected.indexOf(name) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, name)}
                      />
                    </TableCell>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{enName}</TableCell>
                    <TableCell align="left">{releaseDate}</TableCell>
                    <TableCell align="left">
                      <Label variant="ghost" color={(enabled === 0 && 'error') || 'success'}>
                        {enabled === 1 ? '正常' : '下架'}
                      </Label>
                    </TableCell>

                    <TableCell align="right">
                      <TableMoreMenu>
                        <MenuItem
                          component={RouterLink}
                          to={`edit/${id}`}
                          sx={{ color: 'text.secondary' }}
                        >
                          <ListItemIcon>
                            <Icon icon={editFill} width={24} height={24} />
                          </ListItemIcon>
                          <ListItemText
                            primary="编辑"
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </MenuItem>
                        <MenuItem sx={{ color: 'text.secondary' }}>
                          <ListItemIcon>
                            <Icon icon={plusFill} width={24} height={24} />
                          </ListItemIcon>
                          <ListItemText
                            primary="加入精选"
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </MenuItem>
                        {enabled === 1 && (
                          <MenuItem
                            sx={{ color: 'text.secondary' }}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: '确定要下架吗？',
                                subTitle: '下架之后只有管理员才能够查看',
                                onConfirm: () => {
                                  onTakeOff(id);
                                }
                              });
                            }}
                          >
                            <ListItemIcon>
                              <Icon icon={videoOffFill} width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText
                              primary="下架"
                              primaryTypographyProps={{ variant: 'body2' }}
                            />
                          </MenuItem>
                        )}
                        {enabled === 0 && (
                          <MenuItem
                            sx={{ color: 'text.secondary' }}
                            onClick={() => {
                              setConfirmDialog({
                                isOpen: true,
                                title: '确定要上架吗？',
                                subTitle: '上架之后可能会有版权问题',
                                onConfirm: () => {
                                  onPutOn(id);
                                }
                              });
                            }}
                          >
                            <ListItemIcon>
                              <Icon icon={videoFill} width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText
                              primary="上架"
                              primaryTypographyProps={{ variant: 'body2' }}
                            />
                          </MenuItem>
                        )}
                        <MenuItem
                          sx={{ color: 'text.secondary' }}
                          onClick={() => {
                            setConfirmDialog({
                              isOpen: true,
                              title: '确定要删除这条数据吗？',
                              subTitle: '删除后无法恢复',
                              onConfirm: () => {
                                onDelete(id);
                              }
                            });
                          }}
                        >
                          <ListItemIcon>
                            <Icon icon={trash2Outline} width={24} height={24} />
                          </ListItemIcon>
                          <ListItemText
                            primary="删除"
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </MenuItem>
                      </TableMoreMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
