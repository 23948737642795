import cookie from 'react-cookies';
import axios from 'axios';

// const BASE_URL = 'http://192.168.50.71/api/kanrisha/';
// const BASE_URL = 'http://127.0.0.1:9999/api/kanrisha/';
const BASE_URL = 'https://api.24pindao.com/api/kanrisha/';

export const ENDPOINTS = {
  GET_TODAY_MOVIE: 'movie/getTodayMovie',
  GET_TODAY_TV: 'tv/getTodayTv',
  GET_STATISTICS_OVERVIEW: 'statistics/getOverview',
  GET_STATISTICS_DISTRIC: 'statistics/getDistrict',
  GET_STATISTICS_WORLD: 'statistics/getWorld',
  GET_MOVIE_COUNT: 'movie/getTotalMovies',
  GET_TV_COUNT: 'tv/getTotalTvs',
  GET_MOVIE_INFO_LIST: 'movie/getMovieInfoList',
  GET_MOVIE_INFO: 'movie/getMovieInfo',
  GET_MOVIE_RESOURCE_LIST: 'movie/getMovieResourceList',
  UPDATE_MOVIE_INFO: 'movie/updateMovieInfo',
  GET_MOVIE_RESOURCE: 'movie/getMovieResource',
  DELETE_MOVIE_INFO: 'movie/deleteMovieInfo',
  UPDATE_MOVIE_RESOURCE: 'movie/updateMovieResource',
  DELETE_MOVIE_RESOURCE: 'movie/deleteMovieResource',
  TAKE_OFF_MOVIE: 'movie/takeOffMovie',
  PUT_ON_MOVIE: 'movie/putOnMovie',
  GET_TV_SERIES_LIST: 'tv/getTvSeriesList',
  GET_TV_SERIES: 'tv/getTvSeries',
  GET_TV_RESOURCE_LIST: 'tv/getTvResourceList',
  GET_TV_RESOURCE: 'tv/getTvResource',
  UPDATE_TV_INFO: 'tv/updateTvInfo',
  ADD_TV_INFO: 'tv/addTvInfo',
  ADD_MOVIE_INFO: 'movie/addMovieInfo',
  UPDATE_TV_RESOURCE: 'tv/updateTvResource',
  DELETE_TV_SERIES: 'tv/deleteTvSeries',
  DELETE_TV_RESOURCE: 'tv/deleteTvResource',
  TAKE_OFF_TV: 'tv/takeOffTvSeries',
  PUT_ON_TV: 'tv/putOnTvSeries',
  SCAN_TEMP_TV: 'tv/scanTempTv',
  SCAN_TEMP_MOVIE: 'movie/scanTempMovie',
  ADD_TV_RESOURCE: 'tv/addTvResource',
  ADD_MOVIE_RESOURCE: 'movie/addMovieResource',
  GET_COMMENT_LIST: 'comment/getCommentList',
  GET_CONTACT_LIST: 'comment/getContactList',
  DELETE_COMMENT: 'comment/deleteComment',
  GET_UNTRACKED_MOVIE: 'untracked/movie/list',
  GET_UNTRACKED_TV: 'untracked/tv/list',
  GET_ACCESS_LOG: 'log/accessLog',
  GET_SEARCH_LOG: 'log/searchLog',
  USER_LOGIN: 'auth/user/login',
  USER_INFO: 'auth/user/info',
  GET_QUEUE_SIZE: 'statistics/getQueueSize',
  GET_SEARCH_ENGINE_STATUS: 'statistics/getSearchEngineStatus',
  GET_NOTICE: 'statistics/getNotice',
  GET_IS_QUOTE: 'statistics/getIsQuote',
  GET_IS_ALLOW_SPONSOR: 'statistics/getIsAllowSponsor',
  UPDATE_NOTICE: 'statistics/updateNotice',
  SET_IS_QUOTE: 'statistics/setQuote',
  SET_IS_ALLOW_SPONSOR: 'statistics/setAllowSponsor',
  UPLOAD_IMG: 'tool/img/upload',
  GET_ALL_RESOURCE_DEFINE_CODE: 'code/getAllCodeDefine',
  GET_RESOURCE_DEFINE_CODE: 'code/getCodeDefine',
  SAVE_RESOURCE_DEFINE_CODE: 'code/saveCodeDefine',
  GET_COOKIES_115: 'tool/offline/cookies/115/get',
  UPDATE_COOKIES_115: 'tool/offline/cookies/115/update'
};

// const token = cookie.load('token');

const instance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true, // 跨域携带Cookies
  timeout: 50000
  // headers: {
  //   Authorization: `Bearer ${token}`
  // }
});

const unsafe = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  timeout: 50000
});

export const createAPIEndpoint = (endpoint) => {
  // const url = BASE_URL + endpoint;
  // const token = cookie.load('token');
  // console.log(token);
  // axios.interceptors.request.use(
  //   (config) => {
  //     // config.headers.Authorization = `Bearer ${token}`;
  //     config.withCredentials = true;
  //     return config;
  //   },
  //   (error) => Promise.reject(error)
  // );
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        localStorage.removeItem('roles');
        cookie.remove('token');
        // 请求要求身份验证 跳转到登录页
        window.location.href = '/kanrisha/login';
      }
    }
  );
  return {
    get: async (param) => instance.get(endpoint, param),
    post: async (body) => instance.post(endpoint, body)
  };
};

export const createUnAuthApiEndpoint = (endpoint) => ({
  get: async (param) => unsafe.get(endpoint, param),
  post: async (body) => unsafe.post(endpoint, body)
});

export const createUploadApiEndpoint = (endpoint) => ({
  post: (files) => {
    console.log(files.files);
    const formData = new FormData();
    formData.append('file', files.files[0]);
    return instance.post(endpoint, formData);
  }
});
