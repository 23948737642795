import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import cornerUpLeftFill from '@iconify/icons-eva/corner-up-left-fill';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Card, Stack, Typography, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// components
import Page from '../components/Page';
import InputTextField from '../components/InputTextField';
import Label from '../components/Label';
import { Form } from '../components/useForm';
import { createAPIEndpoint, ENDPOINTS } from '../api';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  inputShort: {
    width: '80%'
  },
  inputLong: {
    margin: theme.spacing(1),
    width: '75%'
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

export default function ResourceCodeEdit() {
  const { id } = useParams();
  const classes = useStyles();

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

  const [name, setName] = useState('');
  const [zhName, setZhName] = useState('');
  const [code, setCode] = useState('');
  const [info, setInfo] = useState('');
  const [enable, setEnable] = useState(1);

  const handleSubmit = (e) => {
    e.preventDefault();
    createAPIEndpoint(ENDPOINTS.SAVE_RESOURCE_DEFINE_CODE)
      .post({
        id,
        name,
        zhName,
        code,
        info,
        enable
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '更新成功',
            type: 'success'
          });
        } else {
          setNotify({
            isOpen: true,
            message: '更新失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  };

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_RESOURCE_DEFINE_CODE)
      .get({
        params: {
          id
        }
      })
      .then((res) => {
        console.log(res);
        const item = res.data.data;
        setCode(item.code);
        setName(item.name);
        setZhName(item.zhName);
        setInfo(item.info);
        setEnable(item.enable);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, []);

  const handleGoBack = () => {
    window.history.back(-1);
  };

  const resetForm = () => {
    setName('');
    setZhName('');
    setCode('');
    setInfo('');
    setEnable('');
  };

  return (
    <Page title="资源码编辑 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            美剧编辑
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={cornerUpLeftFill} />}
            onClick={handleGoBack}
          >
            返回
          </Button>
        </Stack>
        <Card>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={3} className={classes.pageContent}>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                justifyContent="space-between"
              >
                <Typography variant="h6" gutterBottom>
                  基本信息
                </Typography>
                <Label variant="ghost" color={(enable === 0 && 'error') || 'success'}>
                  {enable === 1 ? '有效' : '失效'}
                </Label>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="编码"
                  name="code"
                  fullWidth
                  value={code}
                  setValue={setCode}
                  required
                  disabled
                />
                <InputTextField
                  label="资源名"
                  name="name"
                  fullWidth
                  value={name}
                  setValue={setName}
                  required
                />
                <InputTextField
                  label="中文名"
                  name="zhName"
                  fullWidth
                  value={zhName}
                  setValue={setZhName}
                  required
                />
                <InputTextField
                  label="状态（1:有效，0:无效）"
                  name="enable"
                  fullWidth
                  value={enable}
                  setValue={setEnable}
                  required
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="额外信息"
                  name="info"
                  fullWidth
                  value={info}
                  setValue={setInfo}
                  multiline
                  minRows={5}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  startIcon={<Icon icon={checkmarkCircleFill} />}
                  type="submit"
                >
                  提交
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<Icon icon={refreshFill} />}
                  onClick={resetForm}
                >
                  重置
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
