import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import cornerUpLeftFill from '@iconify/icons-eva/corner-up-left-fill';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import { Card, Stack, Typography, Button, Container, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// components
import Page from '../components/Page';
import InputTextField from '../components/InputTextField';
import { Form } from '../components/useForm';
import { createAPIEndpoint, ENDPOINTS } from '../api';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  inputShort: {
    width: '80%'
  },
  inputLong: {
    margin: theme.spacing(1),
    width: '75%'
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

export default function MovieResourceEdit() {
  const { id } = useParams();
  const classes = useStyles();

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

  const [name, setName] = useState('');
  const [size, setSize] = useState('');

  const codeDefine = JSON.parse(localStorage.getItem('resource_code_define'));

  const handleSubmit = (e) => {
    e.preventDefault();
    const resourceJson = [];
    const resMap = {};
    Object.values(e.target.elements).forEach((element) => {
      Object.values(codeDefine).forEach((define) => {
        let res = {};
        // component name跟资源name相等的
        if (define.name === element.name && element.value) {
          res = { value: element.value, ...res };
          resMap[define.name] = res;
        }
        if (`${define.name}_info` === element.name && element.value) {
          res = resMap[define.name];
          res = { info: element.value, ...res };
          resMap[define.name] = res;
        }
      });
    });
    Object.entries(resMap).forEach((res) => {
      const name = res[0];
      let code = 0;
      Object.values(codeDefine).forEach((define) => {
        if (define.name === name) {
          code = define.code;
        }
      });
      let value = res[1];
      value = { code, ...value };
      // 组装jsonarray
      resourceJson.push(value);
    });
    console.log(resourceJson);

    createAPIEndpoint(ENDPOINTS.ADD_MOVIE_RESOURCE)
      .post({
        name,
        size,
        mid: id,
        resourceList: resourceJson
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '更新成功',
            type: 'success'
          });
          handleGoBack();
        } else {
          setNotify({
            isOpen: true,
            message: '更新失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  };

  const handleGoBack = () => {
    window.history.back(-1);
  };

  const resetForm = () => {
    setName('');
    setSize('');
  };

  const constructInputComponent = () => {
    if (codeDefine == null) {
      return [];
    }
    const component = [];
    Object.values(codeDefine).forEach((define) => {
      component.push(
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} key={define.name}>
          <TextField fullWidth label={define.zhName} name={define.name} />
          <TextField
            name={`${define.name}_info`}
            fullWidth
            placeholder="额外信息，比如网盘密码之类"
          />
        </Stack>
      );
    });
    return component;
  };

  return (
    <Page title="新增电影下载资源 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            新增电影下载资源
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={cornerUpLeftFill} />}
            onClick={handleGoBack}
          >
            返回
          </Button>
        </Stack>
        <Card>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={3} className={classes.pageContent}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Typography variant="h6" gutterBottom>
                  基本信息
                </Typography>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="资源名"
                  name="name"
                  fullWidth
                  value={name}
                  setValue={setName}
                  required
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField label="电影ID" name="mid" fullWidth value={id} disabled required />
                <InputTextField
                  label="大小"
                  name="size"
                  fullWidth
                  value={size}
                  setValue={setSize}
                  required
                />
              </Stack>
              {constructInputComponent()}

              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  startIcon={<Icon icon={checkmarkCircleFill} />}
                  type="submit"
                >
                  提交
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<Icon icon={refreshFill} />}
                  onClick={resetForm}
                >
                  重置
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
