import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import cornerUpLeftFill from '@iconify/icons-eva/corner-up-left-fill';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Card, Stack, Typography, Button, Container, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// components
import Page from '../components/Page';
import InputTextField from '../components/InputTextField';
import { Form } from '../components/useForm';
import { createAPIEndpoint, createUploadApiEndpoint, ENDPOINTS } from '../api';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  inputShort: {
    width: '80%'
  },
  inputLong: {
    margin: theme.spacing(1),
    width: '75%'
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

export default function MovieAdd() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [uploading, setUploading] = useState(false);

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

  const [name, setName] = useState('');
  const [enName, setEnName] = useState('');
  const [alias, setAlias] = useState('');
  const [genre, setGenre] = useState('');
  const [country, setCountry] = useState('');
  const [duration, setDuration] = useState('');
  const [releaseDate, setReleaseDate] = useState('');
  const [imdb, setImdb] = useState('');
  const [douban, setDouban] = useState('');
  const [imdbUrl, setImdbUrl] = useState('');
  const [poster, setPoster] = useState('');
  const [description, setDescription] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    createAPIEndpoint(ENDPOINTS.ADD_MOVIE_INFO)
      .post({
        name,
        enName,
        alias,
        genre,
        country,
        duration,
        releaseDate,
        imdb,
        douban,
        imdbUrl,
        poster,
        description
      })
      .then((res) => {
        const mid = res.data.data;
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '更新成功',
            type: 'success'
          });
          navigate(`../edit/${mid}`, { replace: true });
        } else {
          setNotify({
            isOpen: true,
            message: '更新失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  };

  const handleGoBack = () => {
    window.history.back(-1);
  };

  const resetForm = () => {
    setName('');
    setEnName('');
    setAlias('');
    setGenre('');
    setCountry('');
    setDuration('');
    setReleaseDate('');
    setImdb('');
    setDouban('');
    setImdbUrl('');
    setPoster('');
    setDescription('');
  };

  return (
    <Page title="新增电影 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            新增电影
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={cornerUpLeftFill} />}
            onClick={handleGoBack}
          >
            返回
          </Button>
        </Stack>
        <Card>
          <Form onSubmit={handleSubmit}>
            <Stack spacing={3} className={classes.pageContent}>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <Typography variant="h6" gutterBottom>
                  基本信息
                </Typography>
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="电影名"
                  name="name"
                  fullWidth
                  value={name}
                  setValue={setName}
                  required
                />
                <InputTextField
                  label="原名"
                  name="enName"
                  fullWidth
                  value={enName}
                  setValue={setEnName}
                  required
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="别名"
                  name="alias"
                  fullWidth
                  value={alias}
                  setValue={setAlias}
                />
                <InputTextField
                  label="类型"
                  name="genre"
                  fullWidth
                  value={genre}
                  setValue={setGenre}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="时长"
                  name="duration"
                  fullWidth
                  value={duration}
                  setValue={setDuration}
                />
                <InputTextField
                  label="国家"
                  name="country"
                  fullWidth
                  value={country}
                  setValue={setCountry}
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="上映时间"
                  name="releaseDate"
                  fullWidth
                  value={releaseDate}
                  setValue={setReleaseDate}
                />
                <InputTextField
                  label="IMDB地址（爬虫用）"
                  name="imdbUrl"
                  fullWidth
                  value={imdbUrl}
                  setValue={setImdbUrl}
                  required
                />
              </Stack>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                <InputTextField
                  label="IMDB评分"
                  name="imdb"
                  fullWidth
                  value={imdb}
                  setValue={setImdb}
                />
                <InputTextField
                  label="豆瓣评分"
                  name="douban"
                  fullWidth
                  value={douban}
                  setValue={setDouban}
                />
              </Stack>
              <InputTextField
                label="海报"
                name="poster"
                fullWidth
                value={poster}
                setValue={setPoster}
                InputProps={{
                  endAdornment: (
                    <label htmlFor="contained-button-file">
                      <input
                        accept="image/*"
                        id="contained-button-file"
                        type="file"
                        hidden
                        onChange={(e) => {
                          setUploading(true);
                          const { files } = e.target;
                          createUploadApiEndpoint(ENDPOINTS.UPLOAD_IMG)
                            .post({
                              files
                            })
                            .then((res) => {
                              setPoster(res.data.data || res.data.msg);
                            })
                            .catch((err) => {
                              console.log(err);
                            })
                            .finally(() => {
                              setUploading(false);
                            });
                        }}
                      />
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        disabled={uploading}
                      >
                        {uploading ? (
                          <CircularProgress color="inherit" size={20} thickness={6} />
                        ) : (
                          <PhotoCamera />
                        )}
                      </IconButton>
                    </label>
                  )
                }}
                required
              />
              <InputTextField
                label="简介"
                name="description"
                fullWidth
                multiline
                rows={4}
                value={description}
                setValue={setDescription}
              />
              <Stack direction="row" spacing={2} justifyContent="center">
                <Button
                  variant="contained"
                  startIcon={<Icon icon={checkmarkCircleFill} />}
                  type="submit"
                >
                  提交
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  startIcon={<Icon icon={refreshFill} />}
                  onClick={resetForm}
                >
                  重置
                </Button>
              </Stack>
            </Stack>
          </Form>
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
