import { filter } from 'lodash';
import { Icon, InlineIcon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import plusFill from '@iconify/icons-eva/plus-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import alertTriangleOutline from '@iconify/icons-eva/alert-triangle-outline';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import { createAPIEndpoint, ENDPOINTS } from '../api';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { TableListHead, TableListToolbar, TableMoreMenu } from '../components/table';
import Notification from '../components/Notification';
import * as timeUtils from '../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'ip', label: 'IP', alignRight: false },
  { id: 'url', label: 'URL', alignRight: false },
  { id: 'param', label: 'PARAM', alignRight: false },
  { id: 'ua', label: 'USER-AGENT', alignRight: false },
  { id: 'status', label: 'CODE', alignRight: false },
  { id: 'rectime', label: 'TIME', alignRight: false }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.ip.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function AccessLogManage() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('rectime');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });

  const [accessList, setAccessList] = useState([]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_ACCESS_LOG)
      .get()
      .then((res) => {
        console.log(res);
        const accessList = res.data.data.map((item) => ({
          id: item.id,
          ip: item.ip,
          ipInfo: item.ipInfo,
          url: item.url,
          param: item.param,
          status: item.status,
          ua: item.ua,
          rectime: item.rectime,
          bot: item.bot
        }));
        setAccessList(accessList);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = accessList.map((n) => n.ip);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, ip) => {
    const selectedIndex = selected.indexOf(ip);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, ip);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const filteredAccessLog = applySortFilter(accessList, getComparator(order, orderBy), filterName);

  return (
    <Page title="访问记录 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            访问记录
          </Typography>
        </Stack>

        <Card>
          <TableListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={accessList.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredAccessLog
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      const { id, ip, ipInfo, url, param, ua, status, rectime, bot } = row;
                      const isItemSelected = selected.indexOf(id) !== -1;

                      let botDisplay;
                      if (bot === null || bot === false) {
                        botDisplay = (
                          <TableCell align="left">
                            <p>{ip}</p>
                            <p>{ipInfo}</p>
                          </TableCell>
                        );
                      } else {
                        botDisplay = (
                          <TableCell align="left">
                            <p>{ip}</p>
                            <p>{ipInfo}</p>
                            <p>
                              <InlineIcon icon={alertTriangleOutline} />
                            </p>
                          </TableCell>
                        );
                      }

                      return (
                        <TableRow
                          hover
                          key={id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, id)}
                            />
                          </TableCell>
                          {botDisplay}
                          {/* <TableCell align="left">{`${ip}  ${ipInfo}`}</TableCell> */}
                          <TableCell align="left">{ua}</TableCell>
                          <TableCell align="left">{url}</TableCell>
                          <TableCell align="left">{param}</TableCell>
                          <TableCell align="left">{status}</TableCell>
                          <TableCell align="left">{timeUtils.fDateTimeSuffix(rectime)}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={accessList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
    </Page>
  );
}
