import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import messageCircleOutline from '@iconify/icons-eva/message-circle-outline';
import emailOutline from '@iconify/icons-eva/email-outline';
// material
import {
  Card,
  CardHeader,
  Stack,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
// components
import Page from '../../Page';
import { TableMoreMenu } from '../../table';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';
import Label from '../../Label';
import ConfirmDialog from '../../ConfirmDialog';
import useTable from '../../useTable';
import * as timeUtils from '../../../utils/formatTime';

const TABLE_HEAD_COMMENT = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'comment', label: '评论', alignRight: false },
  { id: 'userName', label: '用户名', alignRight: false },
  { id: 'mtName', label: '剧名', alignRight: false },
  { id: 'type', label: '分类', alignRight: false },
  { id: 'rectime', label: '时间', alignRight: false },
  { id: '' }
];

const useStyles = makeStyles((theme) => ({
  pageContent: {
    margin: theme.spacing(3)
  },
  card: {
    marginTop: theme.spacing(3)
  }
}));

export default function Comment() {
  const classes = useStyles();

  const [commentList, setCommentList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const {
    TblContainer: TblContainerComment,
    TblHead: TblHeadComment,
    TblPagination: TblPaginationComment,
    setSelected: setSelectedComment,
    setTotalCount: setTotalCountComment,
    selected: selectedComment,
    page: pageComment,
    rowsPerPage: rowsPerPageComment,
    order: orderComment,
    orderBy: orderByComment,
    totalCount: totalCountComment
  } = useTable(commentList, TABLE_HEAD_COMMENT);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_COMMENT_LIST)
      .get({
        params: {
          page: pageComment,
          pageSize: rowsPerPageComment,
          orderBy: orderByComment,
          order: orderComment
        }
      })
      .then((res) => {
        console.log(res);
        const commentList = res.data.data.list.map((item) => ({
          id: item.id,
          comment: item.comment,
          userName: item.userName,
          mtName: item.mtName,
          type: item.type,
          rectime: item.rectime
        }));
        setTotalCountComment(res.data.data.count);
        setCommentList(commentList);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, [pageComment, orderByComment, orderComment, rowsPerPageComment, totalCountComment]);

  const handleClickComment = (event, id) => {
    const selectedIndex = selectedComment.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedComment, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedComment.slice(1));
    } else if (selectedIndex === selectedComment.length - 1) {
      newSelected = newSelected.concat(selectedComment.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedComment.slice(0, selectedIndex),
        selectedComment.slice(selectedIndex + 1)
      );
    }
    setSelectedComment(newSelected);
  };

  const onDeleteComment = (id) => {
    createAPIEndpoint(ENDPOINTS.DELETE_COMMENT)
      .post({
        id
      })
      .then((res) => {
        if (res.data.code === '1000') {
          setNotify({
            isOpen: true,
            message: '删除成功',
            type: 'success'
          });
        } else {
          setNotify({
            isOpen: true,
            message: '删除失败',
            type: 'warning'
          });
        }
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    setTotalCountComment(totalCountComment - 1);
  };

  return (
    <Card>
      <CardHeader title="评论" />
      <TblContainerComment>
        <TblHeadComment />
        <TableBody>
          {commentList.map((row) => {
            const { id, comment, userName, mtName, type, rectime } = row;
            const isItemSelected = selectedComment.indexOf(id) !== -1;

            return (
              <TableRow
                hover
                key={id}
                tabIndex={-1}
                role="checkbox"
                selected={isItemSelected}
                aria-checked={isItemSelected}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={isItemSelected}
                    onChange={(event) => handleClickComment(event, id)}
                  />
                </TableCell>
                <TableCell align="left">{id}</TableCell>
                <TableCell align="left">
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Icon icon={messageCircleOutline} width={20} height={20} />
                    <Typography variant="subtitle2" noWrap>
                      {comment}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell align="left">{userName}</TableCell>
                <TableCell align="left">{mtName}</TableCell>
                <TableCell align="left">
                  <Label variant="ghost" color={(type === 1 && 'warning') || 'success'}>
                    {type === 1 ? '美剧' : '电影'}
                  </Label>
                </TableCell>
                <TableCell align="left">{timeUtils.fDateTimeSuffix(rectime)}</TableCell>
                <TableCell align="right">
                  <TableMoreMenu>
                    <MenuItem
                      sx={{ color: 'text.secondary' }}
                      onClick={() => {
                        setConfirmDialog({
                          isOpen: true,
                          title: '确定要删除这条数据吗？',
                          subTitle: '删除后无法恢复',
                          onConfirm: () => {
                            onDeleteComment(id);
                          }
                        });
                      }}
                    >
                      <ListItemIcon>
                        <Icon icon={trash2Outline} width={24} height={24} />
                      </ListItemIcon>
                      <ListItemText primary="删除" primaryTypographyProps={{ variant: 'body2' }} />
                    </MenuItem>
                  </TableMoreMenu>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </TblContainerComment>
      <TblPaginationComment />
    </Card>
  );
}
