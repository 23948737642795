import { TextField } from '@material-ui/core';

export default function InputTextField(props) {
  const { name, label, error = null, value, setValue, ...other } = props;

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  return (
    <TextField
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={handleChange}
      {...other}
      {...(error && { error: true, helperText: error })}
    />
  );
}
