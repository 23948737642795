import { Form, FormikProvider, useFormik } from 'formik';
import { Icon } from '@iconify/react';
import checkmarkCircleFill from '@iconify/icons-eva/checkmark-circle-fill';
import { useState, useEffect } from 'react';
// material
import { Box, Button, Card, CardHeader, Stack, Typography } from '@material-ui/core';

import InputTextField from '../../InputTextField';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';

// ----------------------------------------------------------------------

export default function Cookies115() {
  const [cookies, setCookies] = useState('');
  // const [newCookies, setNewCookies] = useState('');

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_COOKIES_115)
      .get()
      .then((res) => {
        const record = res.data.data;
        setCookies(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formik = useFormik({
    initialValues: {
      cookies: ''
    },
    onSubmit: (e) => {
      createAPIEndpoint(ENDPOINTS.UPDATE_COOKIES_115)
        .post({
          cookies
        })
        .then((res) => {
          setCookies(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });

  const { values, handleSubmit } = formik;

  return (
    <Card>
      <CardHeader title="115 Cookies" />
      <Box sx={{ px: 3, py: 1 }}>
        {/* <Typography>{notice}</Typography> */}
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Stack direction="column" spacing={2} alignItems="flex-start">
                <InputTextField
                  label="Cookies"
                  name="cookies"
                  fullWidth
                  multiline
                  rows={2}
                  value={cookies}
                  setValue={setCookies}
                />
              </Stack>
              <Stack direction="column" spacing={2} alignItems="flex-start">
                <Button
                  variant="contained"
                  type="submit"
                  startIcon={<Icon icon={checkmarkCircleFill} />}
                >
                  更新
                </Button>
              </Stack>
            </Stack>
          </Form>
        </FormikProvider>
      </Box>
    </Card>
  );
}
