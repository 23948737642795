import { useState } from 'react';
import { Icon } from '@iconify/react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Stack, Container, Typography, Button } from '@material-ui/core';
import plusFill from '@iconify/icons-eva/plus-fill';
// components
import Page from '../components/Page';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';
import CodeDefine from '../components/CodeDefine';

// ----------------------------------------------------------------------

export default function CodeDefineManage() {
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  return (
    <Page title="资源码管理 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            资源码管理
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="add"
            startIcon={<Icon icon={plusFill} />}
          >
            新增
          </Button>
        </Stack>
        <CodeDefine />
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
