import { Icon } from '@iconify/react';
import { useState, useEffect } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import link2Fill from '@iconify/icons-eva/link-2-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import plusFill from '@iconify/icons-eva/plus-fill';
import cornerUpLeftFill from '@iconify/icons-eva/corner-up-left-fill';
import videoFill from '@iconify/icons-eva/video-fill';
import videoOffFill from '@iconify/icons-eva/video-off-fill';
import { Link as RouterLink } from 'react-router-dom';
// material
import {
  Card,
  Stack,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@material-ui/core';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import { TableListToolbar, TableMoreMenu } from '../components/table';

import { createAPIEndpoint, ENDPOINTS } from '../api';
import Notification from '../components/Notification';
import ConfirmDialog from '../components/ConfirmDialog';
import useTable from '../components/useTable';
import * as timeUtils from '../utils/formatTime';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: '资源名', alignRight: false },
  { id: 'seriesName', label: '美剧名', alignRight: false },
  { id: 'imdb', label: 'IMDB评分', alignRight: false },
  { id: 'createTime', label: '采集时间', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

export default function UntrackedTvManage() {
  const [filterName, setFilterName] = useState('');
  const [tvList, setTvList] = useState([]);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' });

  const {
    TblContainer,
    TblHead,
    TblPagination,
    setSelected,
    setTotalCount,
    setPage,
    selected,
    page,
    rowsPerPage,
    order,
    orderBy,
    totalCount
  } = useTable(tvList, TABLE_HEAD);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_UNTRACKED_TV)
      .get({
        params: {
          page,
          pageSize: rowsPerPage,
          keyword: filterName,
          orderBy,
          order
        }
      })
      .then((res) => {
        console.log(res);
        const movieList = res.data.data.list.map((item) => ({
          id: item.id,
          name: item.name,
          seriesName: item.seriesName,
          imdb: item.imdb,
          imdbUrl: item.imdbUrl,
          rarbgUrl: item.rarbgUrl,
          createTime: item.createTime
        }));
        setTotalCount(res.data.data.count);
        setTvList(movieList);
      })
      .catch((err) => {
        setNotify({
          isOpen: true,
          message: err.message,
          type: 'error'
        });
      });
  }, [page, orderBy, order, rowsPerPage, filterName, totalCount]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleGoBack = () => {
    window.history.back(-1);
  };

  return (
    <Page title="未收录美剧管理 | BRILLIANT">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            未收录美剧管理
          </Typography>
          <Button
            variant="contained"
            startIcon={<Icon icon={cornerUpLeftFill} />}
            onClick={handleGoBack}
          >
            返回
          </Button>
        </Stack>

        <Card>
          <TableListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
          />

          <TblContainer>
            <TblHead />
            <TableBody>
              {tvList.map((row) => {
                const { id, name, seriesName, imdb, createTime, imdbUrl, rarbgUrl } = row;
                const isItemSelected = selected.indexOf(name) !== -1;

                return (
                  <TableRow
                    hover
                    key={id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        onChange={(event) => handleClick(event, name)}
                      />
                    </TableCell>
                    <TableCell align="left">{id}</TableCell>
                    <TableCell align="left">{name}</TableCell>
                    <TableCell align="left">{seriesName}</TableCell>
                    <TableCell align="left">{imdb}</TableCell>
                    <TableCell align="left">{timeUtils.fDateTimeSuffix(createTime)}</TableCell>

                    <TableCell align="right">
                      <TableMoreMenu>
                        <MenuItem
                          sx={{ color: 'text.secondary' }}
                          onClick={() => {
                            window.open(imdbUrl, '_blank');
                          }}
                        >
                          <ListItemIcon>
                            <Icon icon={link2Fill} width={24} height={24} />
                          </ListItemIcon>
                          <ListItemText
                            primary="查看IMDB"
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </MenuItem>
                        <MenuItem
                          sx={{ color: 'text.secondary' }}
                          onClick={() => {
                            window.open(rarbgUrl, '_blank');
                          }}
                        >
                          <ListItemIcon>
                            <Icon icon={link2Fill} width={24} height={24} />
                          </ListItemIcon>
                          <ListItemText
                            primary="查看RARBG"
                            primaryTypographyProps={{ variant: 'body2' }}
                          />
                        </MenuItem>
                      </TableMoreMenu>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
          <TblPagination />
        </Card>
      </Container>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </Page>
  );
}
