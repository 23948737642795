import faker from 'faker';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import flashFill from '@iconify/icons-eva/flash-fill';
import { useState, useEffect } from 'react';
// material
import {
  Box,
  Grid,
  Card,
  Switch,
  FormControlLabel,
  CardHeader,
  CardContent,
  Button
} from '@material-ui/core';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';

import { createAPIEndpoint, ENDPOINTS } from '../../../api';

// ----------------------------------------------------------------------

export default function Shortcut() {
  const [isQuote, setIsQuote] = useState(false);
  const [isAllowSponsor, setIsAllowSponsor] = useState(false);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_IS_QUOTE)
      .get()
      .then((res) => {
        const record = res.data.data;
        setIsQuote(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isQuote, setIsQuote]);

  useEffect(() => {
    createAPIEndpoint(ENDPOINTS.GET_IS_ALLOW_SPONSOR)
      .get()
      .then((res) => {
        const record = res.data.data;
        setIsAllowSponsor(record);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [isAllowSponsor, setIsAllowSponsor]);

  const handleChangeSponsor = (e) => {
    createAPIEndpoint(ENDPOINTS.SET_IS_ALLOW_SPONSOR)
      .post({
        allowSponsor: e.target.checked
      })
      .then((res) => {
        setIsAllowSponsor(!isAllowSponsor);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangeQuote = (e) => {
    createAPIEndpoint(ENDPOINTS.SET_IS_QUOTE)
      .post({
        quote: e.target.checked
      })
      .then((res) => {
        setIsQuote(!isQuote);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Card>
      <CardHeader title="快捷操作" />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={isAllowSponsor}
                  onClick={handleChangeSponsor}
                  name="sponsor"
                  color="primary"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="广告推广"
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Switch
                  checked={isQuote}
                  onClick={handleChangeQuote}
                  name="quote"
                  color="primary"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="每日一句"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
